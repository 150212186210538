import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IframeComponent } from './components/iframe/iframe.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/compiler';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
    
  ],
  exports: [],
  schemas: [ ]
})
export class SharedModule { }
