import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

//import { SolverComponent } from '../modules/solver/pages/solver/solver.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'scanner',
  //   pathMatch: 'full'
  // },

 
  {
    path: 'auth',
    loadChildren: () =>
    import('./modules/auth/auth.module').then(m => m.AuthModule),
    
  },
  {
    path: 'scanner',
    loadChildren: () =>
    import('./modules/scanner/scanner.module').then(m => m.ScannerModule),
    data: {animation: 'Scanner'},
    canActivate: [AuthGuard]
  },

  {
    path: 'homeworks',
    loadChildren: () =>
    import('./modules/homework/homework.module').then(m => m.HomeworkModule),
    canActivate: [AuthGuard],
    
  },
  {
    path: 'solver',
    loadChildren: () =>
    import('./modules/solver/solver.module').then(m => m.SolverModule),
    data: {animation: 'Solver'},
    canActivate: [AuthGuard]
    
  },
  {
    path: 'pricelist',
    loadChildren: () =>
    import('./modules/pricelist/pricelist.module').then(m => m.PricelistModule),
    data: {animation: 'Solver'},
    canActivate: [AuthGuard]
    
  },
  {
    path: 'profile',
    loadChildren: () =>
    import('./modules/profile/profile.module').then(m => m.ProfileModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () =>
    import('./modules/home/home.module').then(m => m.HomeModule),
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
