export const environment = {
    production: true,
    apiUrl: 'https://app.owler.pl',
    webSocketUrl: 'wss://app.owler.pl',
    authUrl: 'https://owler.pl',
    authPath: {
        apiPath:  'wp-json/solver/v1',
        profileSettings: 'kokpit/settings'
    }
};
