import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HeaderConfig, HeaderConfigInterface } from './header-config.interface';
import { Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private _defaultConfig: HeaderConfigInterface;

  private _config: HeaderConfigInterface;

  public subject = new Subject<HeaderConfigInterface>();

  constructor(private router: Router) {
    this._defaultConfig = new HeaderConfig();
    this._defaultConfig.leftButton.onClick = ($e: any) => { this.router.navigate(['/']); };
    this._config = this.defaultConfig;
  }

  public initConfig(config: HeaderConfigInterface) {
    this._config = { ...this._config, ...config };
    this.subject.next(this._config);
  }

  public get config(): HeaderConfig {
    return this._config;
  }

  public get defaultConfig(): HeaderConfigInterface {
    const { classes, leftButton, rightButton } = this._defaultConfig
    const cloneConfig = { 
      classes: classes, 
      leftButton: { ...leftButton }, 
      rightButton: { ...rightButton } 
    }

    return cloneConfig;
  }

  public clear() {
    this._config = this.defaultConfig;
    this.subject.next(this._config);
  }
}
