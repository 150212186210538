<div id="menu-sidebar-left-5" class="bg-white menu menu-box-left" [ngClass]="{'menu-active' : active }"  data-menu-width="320" data-menu-effect="menu-push" style="display: block; width: 320px;">
    <div class="divider mb-3"></div>
    <div class="ps-3 pe-3 pt-2 mb-4">
      <div class="d-flex">
        <div class="flex-grow-1 align-self-center ps-2">
          <h1 class="font-700 font-32 mb-0 pb-4"><i>✨</i>Owler</h1>
        </div>
      </div>
    </div>
  
   
  
    <div class="me-3 ms-3">
      <div class="list-group list-custom-small mb-3 pb-3">
        <a routerLink='/scanner' class="color-black border-0" (click)="toggle()">
          <i>📷</i>
          <span>Zeskanuj zadanie</span>
        </a>
        <a routerLink='/homeworks' class="color-black border-0" (click)="toggle()">
          <i>📚</i>
          <span>Moje prace domowe</span>
        </a>
        <a routerLink='/pricelist' class="border-0" (click)="toggle()">
          <i>🚀</i>
          <span>Pakiety</span>
        </a>
      </div>
  
      <div class="list-group list-custom-small pb-3">
      
        <a routerLink='/profile/settings'  class="border-0" (click)="toggle()">
          <i>👨🏻‍🎓</i>
          <span>Profil</span>
        </a>

        <a href="#" class="border-0" (click)="toggle()" (click)="toggle()">
          <i>🧾</i>
          <span>Płatności</span>
        </a>
        <a href="#" class="border-0">
          <i>💬</i>
          <span>Kontakt</span>
        </a>
      </div>
      <div class="list-group list-custom-small mt-3 border-0">
        <button type="button" (click)="logout()" >
          <i>👋</i>
          <span>Wyloguj</span>
        </button>
      </div>
    </div>
  </div>
 <div class="menu-hider" [ngClass]="{'menu-active' : active }" (click)="toggle()"></div> 