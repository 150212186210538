import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, RouterModule } from '@angular/router';

@Component({
  selector: 'app-fab',
  standalone: true,
  imports: [RouterModule],
  templateUrl: './fab.component.html',
  styleUrl: './fab.component.scss'
})
export class FabComponent implements OnInit {
  showFab = true;
  hiddenRoutes = ['/scanner', '/auth/login', '/auth/register'];

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showFab = !this.hiddenRoutes.includes(event.urlAfterRedirects);
      }
    });
  }
}
