import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'src/app/shared/models/user.model';
import { catchError, shareReplay, tap } from 'rxjs/operators'
import { environment } from 'src/environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private TOKEN_NAME = 'access_token';
  private apiUrl: string = environment.apiUrl;
  private authUrl: string = environment.authUrl;
  private authPath: any = environment.authPath;

  constructor(private jwtHelper: JwtHelperService, private http: HttpClient, private router:Router) { }

  authenticateUser(email: string, password: string) {

    
    return this.http.post<any>(`${this.authUrl}/${this.authPath.apiPath}/login`, { // /api/token/
      'email': email,
      'password': password,
      'apply_cookie': true
    }, { 
      withCredentials: true
    })
    .pipe(
      catchError(error => {
  
        return throwError(error);
      }),
      tap(response => {
        localStorage.setItem(this.TOKEN_NAME, response.data.access)
      }),
      shareReplay()
    );

  }

  public refreshToken(token: string)  {
    this.http.post<any>(`${this.apiUrl}/api/token/refresh/`, {
      refresh: token
    })
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem(this.TOKEN_NAME);
    return !this.jwtHelper.isTokenExpired(token);
  }

  logout(): void {
    localStorage.removeItem(this.TOKEN_NAME);
    this.router.navigate(['/auth/login']);
  }

  getAuthorizationToken(): string|null {
    return localStorage.getItem(this.TOKEN_NAME);
  }


  registerUser(name:string, email:string, password:string){
    return this.http.post<any>(`${this.authUrl}/${this.authPath.apiPath}/register`, {
      'email': email,
      'name': name,
      'password': password
    })
    .pipe(
      catchError(error => {
        return throwError(error);
      }),
      tap(response => {
      
      }),
      shareReplay()
    );

  }
}
