<div class="header header-fixed" [ngClass]="config.classes">
    
    <button type="button" *ngIf="leftButtonConfig.active == true" (click)="onLeftButtonClick($event)"  class="header-icon header-icon-1 header-left-button">
        <i class="fas" [ngClass]="'fa-' + leftButtonConfig.icon"></i>
    </button>
    
    <button (click)="onClickLogo($event)" class="header-title"><i>✨</i>Owler</button>

    <button type="button" *ngIf="rightButtonConfig.active == true"  (click)="onRightButtonClick($event)"  class="header-icon header-icon-4 header-right-button">
        <i class="fas" [ngClass]="'fa-' + rightButtonConfig.icon"></i>
    </button>
</div>