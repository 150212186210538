import { Component, Input } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {

  public active: boolean = false;

  constructor(private authService: AuthService) {
  }

  public toggle() {
    this.active = !this.active;
  }

  public logout() {
    this.toggle();
    this.authService.logout();
  }

}
