import { NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MathjaxModule } from "mathjax-angular";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { LayoutModule } from './core/layout.module'
import { ReactiveFormsModule } from '@angular/forms';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { AuthGuard } from './guards/auth.guard';
import { SharedModule } from './shared/shared.module';
import { FabComponent } from './shared/components/fab/fab.component';

export function tokenGetter() {
  return localStorage.getItem("access_token");
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    MathjaxModule.forRoot({config: {
      "tex": {
        "inlineMath": [["$", "$"], ['\\(', '\\)']],
      }
    }}),
    LayoutModule,
    SharedModule,
    JwtModule.forRoot({
      config: {
         tokenGetter: tokenGetter,
        allowedDomains: ["app.owler.pl"],
        //disallowedRoutes: ["http://example.com/examplebadroute/"],
      },
    }),
    FabComponent
  ],
  providers: [
    provideAnimationsAsync(),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    AuthGuard,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
