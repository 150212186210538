import { Component, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { HeaderService } from '../../services/header.service';
import { HeaderButtonConfig, HeaderConfig } from '../../services/header-config.interface';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements AfterViewInit, OnDestroy {

  public config: HeaderConfig;

  @Output()
  public menuButtonClick = new EventEmitter();

  constructor(private headerService: HeaderService, private router: Router) {
    this.config = headerService.config;
  }
  ngOnDestroy(): void {
    this.headerService.clear();
  }

  ngAfterViewInit(): void {
    this.headerService.subject.subscribe((config: HeaderConfig) => {
      this.config = config;
    })
  }

  public onLeftButtonClick(event: any): void {
    if (this.leftButtonConfig.onClick instanceof Function) {
      this.leftButtonConfig.onClick(event);
    }
  }


  public onRightButtonClick(event: any): void {
    if (this.rightButtonConfig.onClick instanceof Function) {
      this.rightButtonConfig.onClick(event);

    }

    this.menuButtonClick.emit();
  }

  public get leftButtonConfig(): HeaderButtonConfig {
    return this.config.leftButton;
  }

  public get rightButtonConfig(): HeaderButtonConfig {
    return this.config.rightButton;
  }

  public onClickLogo(event: any): void {
    this.router.navigate(['/']);
  }

}
