
export interface HeaderButtonConfigInterface {
    active: boolean
    icon: string;
    onClick: CallableFunction;
}

export interface HeaderConfigInterface {
    classes: string;
    leftButton: HeaderButtonConfig;
    rightButton: HeaderButtonConfig; 
}


export class HeaderButtonConfig implements HeaderButtonConfigInterface {
    active: boolean = true;
    icon: string = '';
    onClick: CallableFunction = ($e:any)=> {};

    constructor(icon: string){
        this.icon = icon
    }
}

export class HeaderConfig implements  HeaderConfigInterface{
    classes: string = '';
    leftButton: HeaderButtonConfigInterface = new HeaderButtonConfig('chevron-left');
    rightButton: HeaderButtonConfig = new HeaderButtonConfig('bars');
}